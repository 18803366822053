import $ from 'jquery';

$(document).on("DOMNodeRemoved",".stripe_checkout_app", function (){
    $('.overlay').hide();
});

let $payButton = $('.stripe-payment-button');

if ($payButton.length) {
    $.getScript("https://checkout.stripe.com/checkout.js", function () {
        $payButton.on('click', function (e) {

            let $form = $payButton.closest('form');

            $('.is-invalid-input').removeClass('is-invalid-input');
            $('.is-invalid-label').removeClass('is-invalid-label');

            if ($form[0].checkValidity() === false) {
                $form.find(':invalid').addClass('is-invalid-input').closest('.form-group').find('label').addClass('is-invalid-label');
                alert("The form seems to be invalid.");
                return false;
            }

            $('.overlay').show();

            let $total = $payButton.data('amount');

            StripeCheckout.configure({
                key: "public_stripe_key",
                image: "https://fulldomain/img/logo.png",
                name: $payButton.data('title'),
                description: $payButton.data('description'),
                amount: $total * 100,
                billingAddress: false,
                email: $payButton.data('email'),
                currency: "AUD",
                panelLabel: "Pay",
                allowRememberMe: false,
                token: function (token) {
                    $.post(
                        '/process-payment/process.php',
                        {
                            'description': $payButton.data('description'),
                            'stripe-token': token,
                            'amount': $total
                        },
                        function (data) {
                            if(data.result == 1) {
                                $form.attr('method', 'post').submit();
                            } else {
                                $('.overlay').hide();
                                alert(data.message+"\n\nPlease try again.");
                            }
                        }
                    );
                }
            }).open();

            e.preventDefault();
        });
    });
}
