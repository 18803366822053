import $ from 'jquery';
import whatInput from "what-input";

function throttle (callback, limit) {
    var wait = false;                  // Initially, we're not waiting
    return function () {               // We return a throttled function
        if (!wait) {                   // If we're not waiting
            callback.call();           // Execute users function
            wait = true;               // Prevent future invocations
            setTimeout(function () {   // After a period of time
                wait = false;          // And allow future invocations
            }, limit);
        }
    }
}

let paralaxMethod = function () {
    if (whatInput.ask() !== 'touch') {
        $(para).each(function () {

            var start = $(this).offset().top - $(window).height();
            var end = $(this).offset().top + $(this).outerHeight();

            var reach = $(window).scrollTop() - start;
            var out = $(window).scrollTop() - end;

            var progress = (reach / (Math.abs(reach)+Math.abs(out)));

            if(progress < 0) { progress = 0; }
            if(progress > 1) { progress = 1;}

            // Move in opposite direction
            progress = 1-progress;

            var img = $(this).find('img');

            var move = -(img.height() - $(this).outerHeight()) * progress;

            img.css({'transform': 'translateY('+move+'px)'});

        });
    }
};

var para = $('.b-container');

if (para.length) {
    $(document).on("click scroll", throttle(paralaxMethod, 15));
    $(window).on("load", paralaxMethod);
}
