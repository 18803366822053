import $ from 'jquery';
import whatInput from "what-input";

$('.menu-icon a').click(function() {
    $('.nav-container nav').toggleClass("open");
    $(this).toggleClass("open");
    return false;
});

(function(context){
    let breakPoint = 1024;
    $(context+" nav ul>li>a").click(function(event, force){
        if (whatInput.ask() === 'keyboard' || whatInput.ask() === 'touch' || $(window).width() < breakPoint) {
            if($(this).parent().find("ul").length > 0 && (($(window).width() >= breakPoint && $(this).parent().hasClass('level-1')) || $(window).width() < breakPoint)) {
                if (!$(this).parent().hasClass('open')) {
                    $(this).closest("ul").find('.open').removeClass('open');
                    $(this).parent().addClass('open');
                } else {
                    $(this).parent().removeClass('open');
                }
                event.preventDefault();
            } else if (force) {
                window.location = $(this).attr('href');
            }
            event.stopPropagation();
        }
    }).keydown(function(event){
        if(whatInput.ask() === 'keyboard' && event.keyCode === 32){
            event.preventDefault();
            $(this).trigger('click', true);
        }
    });

    $(context+" .menu-icon a").keydown(function(event){
        if(whatInput.ask() === 'keyboard' && event.keyCode === 32){
            event.preventDefault();
            $(this).trigger('click', true);
        }
    });

    $("body").click(function(event){
        $(context).find('.open').removeClass('open');
    });

    if($(window).width() < breakPoint) {
        $(context+" nav ul ul .active").each(function(){
            $(this).parent().parent().addClass('open');
        });
    }

})((".nav-container "));
