import $ from 'jquery';
import 'lightgallery';
import '../../node_modules/lightgallery/modules/lg-video';

// Light Gallery
if($(".lg-images").length) {
    $(".lg-images").lightGallery({
        selector: 'a'
    });
}
