import $ from 'jquery';
import 'slick-carousel';
import whatInput from "what-input";

let $sliders = $('.slider');

if($sliders.length) {
    $sliders.slick({
        prevArrow:'<button type="button" data-role="none" class="button icon-left-open-big slick-prev" aria-label="Previous" tabindex="0" role="button"><span class="icon svg-icon "><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"></path></svg></span></button>',
        nextArrow:'<button type="button" data-role="none" class="button icon-right-open-big slick-next" aria-label="Next" tabindex="0" role="button"><span class="icon svg-icon "><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"></path></svg></span></button>',
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: false,
        arrows: true,
        dots: true,
        pauseOnDotsHover: true,
        fade: whatInput.ask() !== 'touch'
    });
}
