import $ from 'jquery';

let $dateFields = $('input[type=date]');

if ($dateFields.length > 0) {

    let input = $('<input type="date" name="test0123456" />');

    if (input[0].type != "date" || input.val("_").val() == "_") {

        let $cnd = 'https://cdn.jsdelivr.net/npm/pickadate@3.6.4/lib/compressed/';

        $('head')
            .append('<link rel="stylesheet" href="' + $cnd + 'themes/classic.css" type="text/css" />')
            .append('<link rel="stylesheet" href="' + $cnd + 'themes/classic.date.css" type="text/css" />')
        ;

        $.getScript($cnd + 'picker.js', function () {
            $.getScript($cnd + 'picker.date.js', function () {
                $('body').on('focus', 'input[type=date]', function () {
                    window.jQuery(this).pickadate({
                        firstDay: 1,
                        format: 'dd/mm/yyyy',
                        formatSubmit: 'yyyy-mm-dd',
                        editable: true
                    });
                });
            });
        });
    }
}

