import $ from 'jquery';

$('.global-alert').each(function () {
    let t = $(this);
    t.find('.close-button').click(function () {
        t.addClass('hide');
        localStorage.setItem('global-alert-dismissed-' + t.attr('id'), new Date().getTime().toString());
        return false;
    });

});
