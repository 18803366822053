import $ from 'jquery';
import jQuery from 'jquery';

window.$ = jQuery;
window.jQuery = jQuery;

// Show Old IE Notice
if(!!window.ActiveXObject) {
    $("#ieNotice").removeClass("hide");
}

$(window).trigger('scroll');

